import { getBody } from "./getBody";
import globals from "./globals";

export function lockPageBody(): void {
  const body = getBody();

  globals.scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;

  const scrollTopPixels = `-${globals.scrollTop}px`;

  body.style["overflow"] = "hidden";
  body.style["position"] = "fixed";
  body.style["left"] = "0px";
  body.style["top"] = scrollTopPixels;
  body.style["width"] = "100%";
}
