import { unlockPageBody } from "./unlockPageBody";

export function closeWidgetPopover(iFrameId: string): void {
  const iframe = document.getElementById(iFrameId);

  if (iframe && iframe.parentElement) {
    iframe.parentElement.removeChild(iframe);
  }

  unlockPageBody();
}
