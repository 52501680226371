import { loadButton, popoverConfigMethods } from "./loadButton";
import { receiveMessage } from "./receiveMessage";
import { renderWidget } from "./renderWidget";
import { Appointedd, Widget } from "./Types";

/**
 * A register of widgets that have been rendered.
 */
export const widgets: Widget.Instance[] = [];

declare global {
  interface Window {
    Appointedd: Appointedd;
    buttonScriptActive?: boolean;
  }
}

/**
 * Attach data from our SDK to the global window object.
 */
window.Appointedd = {
  renderWidget,
  widgets,
  popovers: popoverConfigMethods,
};

/**
 * Attach our message listener to the global window object as an event listener.
 */
window.addEventListener("message", receiveMessage);

/**
 * Emitting this event allows consumers to run code that uses the `Appointedd`
 * global window object **after** the SDK is loaded by registering an event
 * listener for this event, effectively avoiding issues where their code relies
 * on the global window object that might not yet be defined.
 */
window.dispatchEvent(
  new CustomEvent("appointedd-booking-tools-sdk-v1", {
    detail: { type: "sdk.ready" },
  }),
);

/**
 * Self-invoking function to initialise the button code on script load.
 */
(function (): void {
  /**
   * We want to make sure this script only runs once.
   *
   * Once buttonScriptActive is set to true we prevent the `initialise()`
   * function from running again in the event of the script being loaded
   * multiple times.
   */
  if (window.buttonScriptActive && window.buttonScriptActive === true) {
    return;
  }

  window.buttonScriptActive = true;

  loadButton();
})();
