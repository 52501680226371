export function getWidgetUrl(): string {
  const runtimeEnv = process.env.DEPLOY_ENV?.toLowerCase() ?? "development";

  let uri = "http://localhost:9001";

  // These code paths can never be hit by tests
  /* istanbul ignore next */
  if (runtimeEnv === "production") {
    uri = "https://booking-tools.appointedd.com";
  } else if (runtimeEnv === "t2") {
    uri = "https://booking-tools-t2.appointedd.com";
  } else if (runtimeEnv === "t1") {
    uri = "https://booking-tools-t1.appointedd.com";
  }

  return uri;
}
