import { Widget } from "../Types";
import { getWidgetUrl } from "./getWidgetUrl";

export const getIFrameSrc = (
  iFrameId: string,
  options: Widget.RenderOptions,
) => {
  const {
    enableLanguageSelector,
    inPopoverMode,
    organisationId,
    preFillData,
    widgetId,
  } = options;
  const {
    bookingNotes,
    customer,
    locale,
    locationSearch,
    locationCoordinates,
    dateTimeSearch,
    displayDateCount,
    serviceId,
    resourceId,
    createdSource,
  } = preFillData ?? {};
  const { firstName, lastName, email, phone, customFields } = customer ?? {};
  const { latitude, longitude } = locationCoordinates ?? {};

  const url = new URL(getWidgetUrl());

  url.searchParams.append("iFrameId", iFrameId);

  if (widgetId) {
    url.searchParams.append("id", widgetId);
  }
  if (enableLanguageSelector) {
    url.searchParams.append("enableLanguageSelector", "true");
  }
  if (inPopoverMode) {
    url.searchParams.append("inPopoverMode", "true");
  }
  if (organisationId) {
    url.searchParams.append("organisationId", organisationId);
  }
  if (bookingNotes) {
    url.searchParams.append("notesPrefill", bookingNotes);
  }
  if (locale) {
    url.searchParams.append("locale", locale);
  }
  if (createdSource) {
    url.searchParams.append("createdSource", createdSource);
  }
  if (serviceId) {
    url.searchParams.append("serviceId", serviceId);
  }
  if (resourceId) {
    url.searchParams.append("resourceId", resourceId);
  }
  if (firstName) {
    url.searchParams.append("customerPrefill[firstname]", firstName);
  }
  if (lastName) {
    url.searchParams.append("customerPrefill[lastname]", lastName);
  }
  if (email) {
    url.searchParams.append("customerPrefill[email]", email);
  }
  if (phone) {
    url.searchParams.append("customerPrefill[mobile]", phone);
  }
  if (customFields) {
    Object.entries(customFields).forEach(([key, value]) =>
      url.searchParams.append(`customerPrefill[customFields][${key}]`, value),
    );
  }
  if (locationSearch) {
    url.searchParams.append("locationSearchPrefill", locationSearch);
  }

  if (dateTimeSearch) {
    url.searchParams.append("dateTimeSearchPrefill", dateTimeSearch);
  }

  if (latitude) {
    url.searchParams.append("locationCoordinatesPrefill[latitude]", latitude);
  }

  if (longitude) {
    url.searchParams.append("locationCoordinatesPrefill[longitude]", longitude);
  }

  if (displayDateCount) {
    url.searchParams.append("displayDateCountPrefill", displayDateCount);
  }

  return url.toString();
};
