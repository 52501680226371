import { getBody } from "./getBody";
import globals from "./globals";

export function unlockPageBody(): void {
  const removeProperty = function (propertyName: string) {
    const body = getBody();
    body.style.removeProperty(propertyName);
  };

  removeProperty("overflow");
  removeProperty("position");
  removeProperty("left");
  removeProperty("top");
  removeProperty("width");

  document.documentElement.scrollTop = document.body.scrollTop =
    globals.scrollTop;
}
