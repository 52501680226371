import { renderWidget } from "./renderWidget";
import { ButtonDataset, Widget, Events } from "./Types";
import { lockPageBody } from "./Utils";

const popoverEventListeners: [string, Events.Listener, string | undefined][] =
  [];

export const popoverConfigMethods = {
  on: (eventName: string, onCallback: Events.Listener, buttonId?: string) => {
    popoverEventListeners.push([eventName, onCallback, buttonId]);
  },
};

export type PopoverConfigMethods = typeof popoverConfigMethods;

export function loadButton(): void {
  const eventSelector = function (
    buttonClassName: string,
    renderPopoverCallback: (button: EventTarget) => void,
  ) {
    /**
     * When the main document is clicked we query all buttons
     * that match the buttonClassName.
     *
     * When we find the one that was clicked we call the eventSelector
     * renderPopoverCallback that renders a booking widget in popover mode based on
     * the provided attributes.
     */
    document.addEventListener("click", function (event) {
      const buttonElements = document.querySelectorAll(buttonClassName);
      if (!buttonElements) return;

      let element = event.target;

      /**
       * Find the clicked element from the array of button elements.
       */
      while (element) {
        const index = Array.prototype.indexOf.call(buttonElements, element);

        if (index !== -1) {
          renderPopoverCallback(element);
          break;
        }

        if (element instanceof HTMLButtonElement) {
          element = element.parentElement;
        } else {
          element = null;
        }
      }
    });
  };

  eventSelector(".appointedd-booking-widget-popover", (button: EventTarget) => {
    if (!(button instanceof HTMLButtonElement)) {
      throw new Error("getButtonAttrs element is not a button");
    }

    const {
      bookingNotes,
      customerEmail,
      customerFirstName,
      customerLastName,
      customerPhone,
      createdSource,
      dateTimeSearch,
      displayDateCount,
      enableLanguageSelector,
      locale,
      locationCoordinatesLatitude,
      locationCoordinatesLongitude,
      locationSearch,
      organisationId,
      serviceId,
      resourceId,
      widgetId,
    } = button.dataset as ButtonDataset;

    const customer = {
      ...(customerEmail && { email: customerEmail }),
      ...(customerFirstName && { firstName: customerFirstName }),
      ...(customerLastName && { lastName: customerLastName }),
      ...(customerPhone && { phone: customerPhone }),
    };

    const locationCoordinates = {
      ...(locationCoordinatesLatitude && {
        latitude: locationCoordinatesLatitude,
      }),
      ...(locationCoordinatesLongitude && {
        longitude: locationCoordinatesLongitude,
      }),
    };

    const preFillData: Widget.PrefillData = {
      ...(bookingNotes && { bookingNotes }),
      ...(Object.entries(customer).length > 0 && { customer }),
      ...(locale && { locale }),
      ...(createdSource && { createdSource }),
      ...(serviceId && { serviceId }),
      ...(resourceId && { resourceId }),
      ...(locationSearch && { locationSearch }),
      ...(dateTimeSearch && { dateTimeSearch }),
      ...(displayDateCount && { displayDateCount }),
      ...(Object.entries(locationCoordinates).length > 0 && {
        locationCoordinates,
      }),
    };

    const customFieldMap = new Map<string, string>();
    for (const [k, v] of Object.entries(button.dataset)) {
      const matchArray = k.match(/customerCustomField\[(.+?)\]/);
      if (!matchArray || !v) {
        continue;
      }
      customFieldMap.set(matchArray[1], v);
    }

    for (const [k, v] of Object.entries(button.dataset)) {
      const matchAttribute = k.match(/customerCustomField\.([^\s.]+)/);
      if (!matchAttribute || !v) {
        continue;
      }

      customFieldMap.set(matchAttribute[1], v);
    }

    if (customFieldMap.size > 0) {
      preFillData.customer ??= {};
      preFillData.customer.customFields = Object.fromEntries(
        customFieldMap.entries(),
      );
    }

    const instance = renderWidget("", {
      widgetId,
      organisationId,
      inPopoverMode: true,
      preFillData,
      enableLanguageSelector: enableLanguageSelector === "true",
    });

    for (const [eventName, onCallback, buttonId] of popoverEventListeners) {
      /**
       * If a button id was specified for an event listener, only
       * attach it if the id's match.
       */
      if (buttonId && buttonId !== button.id) {
        continue;
      }
      instance?.on(eventName, onCallback);
    }

    lockPageBody();
  });
}
