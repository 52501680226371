import { iframeResizer } from "iframe-resizer";
import { Appointedd, Events, Widget } from "./Types";
import { getIFrameSrc } from "./Utils";

declare let window: Appointedd & Window;

export const renderWidget = (
  divId: string,
  options: Widget.RenderOptions,
): Widget.Instance | undefined => {
  const widgetId = options.widgetId;
  const organisationId = options.organisationId;

  if (!widgetId && !organisationId) {
    const errorMessage = "Must provide a widgetId or organisationId";
    console.error(errorMessage, options);
    throw new Error(errorMessage);
  }

  const inPopoverMode = !!options.inPopoverMode;

  const body = document.getElementsByTagName("body")[0];

  const container = inPopoverMode ? body : document.getElementById(divId);

  if (!container) {
    console.error(`Could not find '${divId}' `);
    return;
  }

  const iFrame = document.createElement("iframe");

  const widgets = window.Appointedd.widgets;

  const iFrameId = `appointedd-widget-iFrame-${widgetId}-${widgets.length + 1}`;

  const eventCallbackMap: Events.CallbackMap = new Map();

  const widget = {
    widgetId,
    organisationId,
    iFrameId,
    eventCallbackMap,
    on: (eventName: string, onCallback: Events.Listener) => {
      return eventCallbackMap.set(eventName, onCallback);
    },
  };

  widgets.push(widget);

  /**
   * Even though `scrolling` is deprecated, we still require it
   * as applying scrolling: "no" to an iframe can not be solved by CSS alone.
   *
   * In some browsers like Firefox "overflow:hidden;" is enough to prevent
   * scrolling but on Chrome it seems to ignore the applied CSS.
   *
   * See this blog for more details:
   * https://support.moonpoint.com/network/web/html/css/iframe-obsolete-elements.php
   */
  if (!inPopoverMode) {
    iFrame.scrolling = "no";
  }

  iFrame.id = widget.iFrameId;
  iFrame.title = "Make a Booking";
  iFrame.src = getIFrameSrc(widget.iFrameId, options);
  iFrame.width = "100%";
  iFrame.allow = "geolocation";

  if (inPopoverMode) {
    iFrame.setAttribute("frameBorder", "0");
    iFrame.setAttribute(
      "style",
      "width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 99999999; margin-width: 0; margin-height0;",
    );
  } else {
    iFrame.setAttribute("style", "width:100%;height:100%;border:none;");
  }

  container.appendChild(iFrame);

  if (!inPopoverMode) {
    iframeResizer(
      {
        initCallback: () => {
          console.log("Init");
        },
        closedCallback: () => {
          console.log("Closed");
        },
        log: false,
        checkOrigin: false,
        heightCalculationMethod: "bodyScroll",
      },
      iFrame,
    );
  }

  return widget;
};
