import { Events } from "./Types";
import { closeWidgetPopover } from "./Utils/closeWidgetPopover";

export function receiveMessage(event: MessageEvent<string>): void {
  const data = event.data;

  if (typeof data !== "string") return;
  const source = data.substring(0, data.indexOf("."));
  if (source !== "APPOINTEDD-BOOKING-TOOL") return;

  const jsonString = data.substring(data.indexOf(".") + 1);

  const parsePayloadString = (str: string): Events.MessagePayload => {
    const payload = JSON.parse(str) as Events.MessagePayload;
    if (payload.data === undefined) throw new Error("Invalid payload");
    return payload;
  };

  const payload = parsePayloadString(jsonString);
  const eventName = payload.name;
  const iFrameId = payload.data.iFrameId;

  const widget = window.Appointedd.widgets.find(
    (widget) => widget.iFrameId === iFrameId,
  );

  if (!widget) {
    console.error(`No widget found using the iFrameId ${iFrameId}`);
    return;
  }

  if (eventName === "CLOSE_APPOINTEDD_POPOVER") {
    closeWidgetPopover(iFrameId);
  }

  /**
   * If we receive an event such as READY,
   * call the 'on' callbackFunction associated to the
   * event name for that specific iframe instance.
   */
  const { eventCallbackMap } = widget;
  const eventCallBack = eventCallbackMap.get(eventName);
  if (eventCallBack) {
    eventCallBack(payload);
  }
}
